import _ from 'lodash';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';
import fetch from '../fetch';
import {
  ONBOARD_APP_AVL_LOGGING,
  ONBOARD_APP_TURN_BY_TURN,
  OPERATOR_DISPATCH_MESSAGING,
} from '../optimizely/feature-flags';

const reportError = (fetchConfig, error) =>
  ErrorReporter.capture({
    level: 'error',
    messageOrException: error,
    extraContext: { fetchConfig, localStorage },
    logToConsole: window.cordova != null,
  });

const _isValidLat = (lat) => {
  if (!_.isFinite(lat)) {
    return false;
  }
  if (Math.abs(lat) > 90) {
    return false;
  }
  return true;
};

const _isValidLon = (lon) => {
  if (!_.isFinite(lon)) {
    return false;
  }
  if (Math.abs(lon) > 180) {
    return false;
  }
  return true;
};

const _isValidAvlPushData = (avlData) => {
  // Type checks
  if (!_.isObjectLike(avlData)) {
    return false;
  }
  if (!_.isString(avlData.v)) {
    return false;
  }
  if (!_.isFinite(avlData.t)) {
    return false;
  }
  if (!_isValidLat(avlData.lat)) {
    return false;
  }
  if (!_isValidLon(avlData.lon)) {
    return false;
  }

  // Value checks
  if (!avlData.v) {
    return false;
  }
  if (!avlData.t) {
    return false;
  }

  return true;
};

const _resetCoreState = (vc) => {
  vc.$store.dispatch('navigation/reset');
  vc.$store.commit('currentRouteKey', '');
  vc.$store.commit('currentRouteInfo', null);
  vc.$store.commit('currentVehicleId', '');
  vc.$store.commit('currentVehicleInfo', null);
  vc.$store.commit('lastValidVehicleInfo', null);
  vc.$store.commit('currentVehicleInfoTimestamp', null);
  vc.$store.commit('currentBlockId', '');
  vc.$store.commit('showLoginOverlay', true);
  vc.$store.commit('currentOperator', null);
  vc.$store.commit('messages/resetState');
};

const _isGeolocationSupported = () =>
  _.isFunction(_.get(navigator, ['geolocation', 'watchPosition']));

const _isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;

const _isPermissionsCheckSupported = () =>
  _.isFunction(_.get(navigator, ['permissions', 'query']));

export const requestDeviceRegistration = async (
  { commit, getters },
  { email, password },
) => {
  const uuid = getters.uuid;

  const fetchConfig = {
    method: 'POST',
    url: '/register',
    data: { email, password, uuid },
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  const requestSuccess = _.get(response, ['data', 'success'], false);
  if (requestSuccess) {
    const allowedAgencyKeys = _.get(response, ['data', 'agencies'], []);
    commit('agencyKeys', allowedAgencyKeys);
    commit('registrationStatus', 'registered');
    commit('email', email);
    Segment.track('device-registration', {
      uuid,
      email,
      'allowed-agencies-snapshot': allowedAgencyKeys,
    });
  }

  return {
    success: requestSuccess,
    reason: _.get(response, ['data', 'reason'], ''),
  };
};

export const getAppHash = async ({ commit, getters }) => {
  const fetchConfig = {
    method: 'GET',
    url: '/getAppConfig',
    params: { uuid: getters.uuid },
  };
  try {
    const response = await fetch(fetchConfig);
    return _.get(response, ['data', 'appHash'], null);
  } catch (error) {
    reportError(fetchConfig, error);
  }
  return null;
};

export const getAgencyInfo = async ({ commit, getters }) => {
  const DEFAULT_VALUE = null;

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentAgencyInfo', DEFAULT_VALUE);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getAgencyInfo/${agencyKey}`,
  };
  try {
    const { data } = await fetch(fetchConfig);
    if (data.agencyId != null) {
      commit('currentAgencyInfo', data);
    }
  } catch (error) {
    reportError(fetchConfig, error);
  }
};

export const getAgencyTransitimeConfig = async ({ commit, getters }) => {
  const DEFAULT_VALUE = null;

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentAgencyTransitimeConfig', DEFAULT_VALUE);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getAgencyTransitimeConfig/${agencyKey}`,
  };
  try {
    const response = await fetch(fetchConfig);
    commit(
      'currentAgencyTransitimeConfig',
      _.get(response, ['data', 'config'], DEFAULT_VALUE),
    );
  } catch (error) {
    reportError(fetchConfig, error);
  }
};

export const fetchAgencyConfigs = async ({ commit, getters }) => {
  if (getters.registrationStatus !== 'registered') {
    return;
  }
  const fetchConfig = {
    method: 'GET',
    url: '/agency-configs',
  };
  try {
    const { data } = await fetch(fetchConfig);
    commit('agencyConfigs', data.agencyConfigs);
  } catch (error) {
    reportError(fetchConfig, error);
    throw error;
  }
};

export const getRoutes = async ({ commit, getters }) => {
  const DEFAULT_VALUE = [];
  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentRoutes', DEFAULT_VALUE);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getRoutes/${agencyKey}`,
  };
  try {
    const response = await fetch(fetchConfig);
    commit('currentRoutes', _.get(response, ['data'], DEFAULT_VALUE));
  } catch (error) {
    reportError(fetchConfig, error);
  }
};

export const getVehicles = async ({ commit, getters }) => {
  const DEFAULT_VALUE = [];
  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentVehicles', DEFAULT_VALUE);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getVehicles/${agencyKey}`,
  };
  try {
    const response = await fetch(fetchConfig);
    const currentVehicles = _.get(response, ['data'], DEFAULT_VALUE);
    const filterVehiclesFunction = getters.filterVehiclesFunction;
    if (typeof filterVehiclesFunction === 'function') {
      commit(
        'currentVehicles',
        _.filter(currentVehicles, filterVehiclesFunction),
      );
    } else {
      commit('currentVehicles', currentVehicles);
    }
    commit('serverErrorVehicles', false);
  } catch (error) {
    reportError(fetchConfig, error);
    commit('serverErrorVehicles', true);
  }
};

export const getBlocks = async ({ commit, getters }) => {
  const DEFAULT_VALUE = [];

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentBlocks', DEFAULT_VALUE);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getBlocks/${agencyKey}`,
  };
  try {
    const response = await fetch(fetchConfig);
    commit('currentBlocks', _.get(response, ['data'], DEFAULT_VALUE));
    commit('serverErrorBlocks', false);
  } catch (error) {
    reportError(fetchConfig, error);
    commit('serverErrorBlocks', true);
  }
};

export const getOperatorIds = async ({ commit, getters }) => {
  const DEFAULT_VALUE = [];
  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('currentOperatorIds', DEFAULT_VALUE);
    return;
  }
  const fetchConfig = {
    method: 'GET',
    url: `/operatorIds/${agencyKey}`,
  };
  try {
    const response = await fetch(fetchConfig);
    commit(
      'currentOperatorIds',
      _.get(response, ['data', 'operatorIds'], DEFAULT_VALUE),
    );
  } catch (error) {
    reportError(fetchConfig, error);
  }
};

export const getRouteInfo = async (
  { commit, getters },
  { routeKey, shouldCommit } = { routeKey: undefined, shouldCommit: undefined },
) => {
  const _routeKey =
    typeof routeKey === 'string' ? routeKey : getters.currentRouteKey;
  const _shouldCommit = typeof shouldCommit === 'boolean' ? shouldCommit : true;

  const DEFAULT_VALUE = null;

  const agencyKey = getters.agencyKeyForRequest;

  if (!agencyKey) {
    return DEFAULT_VALUE;
  }
  if (!_routeKey) {
    return DEFAULT_VALUE;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getRouteInfo/${agencyKey}/route/${encodeURIComponent(_routeKey)}`,
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  const responseData = _.get(response, ['data'], DEFAULT_VALUE);
  if (_shouldCommit) {
    commit('currentRouteInfo', responseData);
  }
  return responseData;
};

export const getTripInfo = async ({ commit, getters }, tripId) => {
  const _tripId = typeof tripId === 'string' ? tripId : getters.currentTripId;

  const DEFAULT_VALUE = null;

  const agencyKey = getters.agencyKeyForRequest;

  if (!agencyKey) {
    return DEFAULT_VALUE;
  }
  if (!_tripId) {
    return DEFAULT_VALUE;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getTripInfo/${agencyKey}/trip/${encodeURIComponent(_tripId)}`,
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
    commit('serverErrorTripInfo', false);
  } catch (error) {
    reportError(fetchConfig, error);
    commit('serverErrorTripInfo', true);
  }

  const responseData = _.get(response, 'data', DEFAULT_VALUE);
  commit('currentTripInfo', responseData);
  return responseData;
};

export const fetchMapMatchedPolylines = async ({ commit, getters, state }) => {
  const tripId = getters.currentTripId;
  const agencyKey = getters.agencyKeyForRequest;
  const isNativeAppWithMapSupport = getters.isNativeAppWithMapSupport;

  if (!tripId || !agencyKey || !isNativeAppWithMapSupport) {
    commit('matchedTripPathResponse', null);
    return;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getMatchedTripPath/${agencyKey}/trip/${encodeURIComponent(tripId)}`,
    params: { provider: state.useStadiaPolylines ? 'stadia' : 'mapbox' },
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  const responseData = _.get(response, 'data', null);
  commit('matchedTripPathResponse', responseData);
};

export const getTripCoordinates = async ({ commit, getters }) => {
  const tripId = getters.currentTripId;
  const agencyKey = getters.agencyKeyForRequest;
  const isNativeAppWithMapSupport = getters.isNativeAppWithMapSupport;

  if (!tripId || !agencyKey || !isNativeAppWithMapSupport) {
    commit('currentTripCoordinates', null);
    return;
  }

  let response;
  const fetchConfig = {
    method: 'GET',
    url: `/getTripCoordinates/${agencyKey}/trip/${tripId}`,
  };
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  let responseData = null;
  if (response != null) {
    responseData = response.data;
  }

  commit('currentTripCoordinates', responseData);
};

let _vehicleInfoConsecutiveErrors = 0;

export const getVehicleInfo = async (
  { commit, getters },
  { vehicleId, shouldCommit } = {
    vehicleId: undefined,
    shouldCommit: undefined,
  },
) => {
  const _vehicleId = _.isString(vehicleId)
    ? vehicleId
    : getters.currentVehicleId || getters.permanentlyAssignedVehicleId;
  const _shouldCommit = _.isBoolean(shouldCommit) ? shouldCommit : true;

  const agencyKey = getters.agencyKeyForRequest;

  const DEFAULT_VEHICLE_INFO_VALUE = null;
  const DEFAULT_VEHICLE_INFO_TIMESTAMP = null;

  if (!agencyKey || !_vehicleId) {
    if (_shouldCommit) {
      commit('currentVehicleInfo', DEFAULT_VEHICLE_INFO_VALUE);
      commit('currentVehicleInfoTimestamp', DEFAULT_VEHICLE_INFO_TIMESTAMP);
      commit('currentVehicleInfoAttemptTimestamp', Date.now());
    }
    return DEFAULT_VEHICLE_INFO_VALUE;
  }

  const fetchConfig = {
    method: 'GET',
    url: `/getVehicleInfo/${agencyKey}/vehicle/${_vehicleId}`,
  };
  try {
    const response = await fetch(fetchConfig);
    const responseData = response?.data ?? DEFAULT_VEHICLE_INFO_VALUE;
    if (responseData?.loc != null && getters.isSimulating) {
      const {
        lat: latitude,
        lon: longitude,
        heading: bearing,
        speed,
        time,
      } = responseData.loc;
      try {
        const setLocation = window.NativeAPI?.setLocation;
        if (setLocation != null) {
          setLocation({ latitude, longitude, bearing, speed });
        }
        commit('geolocationEnabledState', 'enabled');
        commit('currentPosition', {
          timestamp: time,
          coords: {
            latitude,
            longitude,
            heading: bearing,
            speed,
            accuracy: 20,
          },
        });
      } catch (error) {
        console.error('Failed to set location', responseData);
      }
    }

    if (_shouldCommit) {
      commit('currentVehicleInfo', responseData);
      commit('currentVehicleInfoTimestamp', Date.now());
      commit('currentVehicleInfoAttemptTimestamp', Date.now());

      if (_.isObjectLike(responseData)) {
        const { lastValidVehicleInfo } = getters;
        const { tripId } = responseData;
        const lastTripId = lastValidVehicleInfo?.tripId;
        if (tripId != null && lastTripId != null && tripId !== lastTripId) {
          commit('lastTripLastVehicleInfo', lastValidVehicleInfo);
        }
        commit('lastValidVehicleInfo', responseData);
      }
    }

    _vehicleInfoConsecutiveErrors = 0;
    commit('serverErrorVehicleInfo', false);

    return responseData;
  } catch (error) {
    commit('currentVehicleInfoAttemptTimestamp', Date.now());

    reportError(fetchConfig, error);

    _vehicleInfoConsecutiveErrors += 1;
    if (_vehicleInfoConsecutiveErrors >= 2) {
      commit('serverErrorVehicleInfo', true);
    }

    return DEFAULT_VEHICLE_INFO_VALUE;
  }
};

export const getServiceAdjustments = async ({ commit, getters }) => {
  const DEFAULT_VALUE = [];

  const enableServiceAdjustments = getters.enableServiceAdjustments;
  if (!enableServiceAdjustments) {
    commit('serviceAdjustments', DEFAULT_VALUE);
    return;
  }
  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    commit('serviceAdjustments', DEFAULT_VALUE);
    return;
  }

  const currentTimeISO = new Date().toISOString();
  const fetchConfig = {
    method: 'GET',
    url: `/getServiceAdjustments/${agencyKey}/currentTime/${currentTimeISO}`,
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }
  const serviceAdjustments = response?.data.serviceAdjustments ?? DEFAULT_VALUE;
  commit('serviceAdjustments', serviceAdjustments);
};

export const sendVehicleAssignment = async (
  { commit, getters },
  { vehicleId, blockId, operatorId },
) => {
  // <debug>
  // console.log( '--- action: sendVehicleAssignment ---' )
  // console.log( `vehicleId: ${ vehicleId }` )
  // console.log( `blockId: ${ blockId }` )
  // console.log( '---' )
  // </debug>

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    return false;
  }

  const blockAssignmentCalls = getters.blockAssignmentCalls;
  if (blockAssignmentCalls) {
    return false;
  }

  const dataToPost = { vehicleId, blockId };
  if (operatorId) {
    dataToPost.operatorId = operatorId;
  }

  const fetchConfig = {
    method: 'POST',
    url: `/sendAssignment/${agencyKey}`,
    data: dataToPost,
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  const requestSuccess = response?.data?.success ?? false;
  if (requestSuccess) {
    commit('vehicleAssignmentError', '');
  } else {
    commit('vehicleAssignmentError', response?.data?.reason ?? 'Unknown error');
  }
  return requestSuccess;
};

export const sendClearVehicleAssignment = async (
  { commit, getters },
  vehicleId,
) => {
  // <debug>
  // console.log( '--- action: sendClearVehicleAssignment ---' )
  // console.log( `vehicleId: ${ vehicleId }` )
  // console.log( '---' )
  // </debug>

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    return false;
  }

  const blockAssignmentCalls = getters.blockAssignmentCalls;
  if (blockAssignmentCalls) {
    return false;
  }

  const dataToPost = { vehicleId };

  const fetchConfig = {
    method: 'POST',
    url: `/sendClearAssignment/${agencyKey}`,
    data: dataToPost,
  };

  let response = undefined;
  try {
    response = await fetch(fetchConfig);
  } catch (error) {
    reportError(fetchConfig, error);
  }

  const requestSuccess = _.get(response, ['data', 'success'], false);
  return requestSuccess;
};

export const sendLocationUpdate = async ({ getters }, options = {}) => {
  const vehicleId =
    options.vehicleId ||
    getters.currentVehicleId ||
    getters.permanentlyAssignedVehicleId;

  const additionalTrackingInfo = {
    ...options,
    vehicle: vehicleId,
    currentVehicleId: getters.currentVehicleId,
    permanentlyAssignedVehicleId: getters.permanentlyAssignedVehicleId,
  };

  // This happens during login
  if (!vehicleId) {
    if (getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)) {
      Segment.track('location-update-failed', {
        ...additionalTrackingInfo,
        errorMessage: 'No vehicle ID',
      });
    }
    return false;
  }

  const position = getters.currentPosition;

  // <debug>
  // console.log( `action sendLocationUpdate for vehicle ${ vehicleId }`, position )
  //
  // Position follows GeolocationCoordinates Interface
  // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
  // Example:
  // {
  //     timestamp : <number> (integer, epoch milliseconds),
  //     coords: {
  //         latitude         : <number> (double, between -90 and 90),
  //         longitude        : <number> (double, between 180 and 180),
  //         accuracy         : <number> (double, see documentation link above) OR null,
  //         heading          : <number> (double, 0-359) OR null,
  //         speed            : <number> (double, meters per second) OR null,
  //         altitude         : <number> (double, meters relative to sea level) OR null,
  //         altitudeAccuracy : <number> (double, see documentation link above) OR null,
  //     }
  // }
  // </debug>

  // If the setting to send GPS updates is not enabled, return early
  if (!getters.sendGeolocationUpdates) {
    return false;
  }

  const agencyKey = getters.agencyKeyForRequest;
  if (!agencyKey) {
    if (getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)) {
      Segment.track('location-update-failed', {
        ...additionalTrackingInfo,
        errorMessage: 'No agency',
      });
    }
    return false;
  }

  const avlData = {
    v: vehicleId,
    t: _.get(position, 'timestamp'),
    lat: _.get(position, ['coords', 'latitude']),
    lon: _.get(position, ['coords', 'longitude']),
  };

  // <testing>
  // For example, run something like below from the console on the login screen
  // window.LOCATION_SPOOFING = true
  // window.LOCATIONS_UPDATES = [
  //     [37.1, -122.1],
  //     [37.2, -122.2],
  //     [37.3, -122.3],
  //     [37.4, -122.4],
  //     [37.5, -122.5],
  // ]
  if (window.LOCATION_SPOOFING === true) {
    console.log('--- intercepted location update ---');
    if (!_.isArray(window.LOCATIONS_UPDATES)) {
      console.log('unable to spoof: LOCATIONS_UPDATES must be an array.');
      console.log('---');
      return;
    } else if (!window.LOCATIONS_UPDATES.length) {
      console.log('unable to spoof: LOCATIONS_UPDATES is empty.');
      console.log('---');
      return;
    }
    const location = window.LOCATIONS_UPDATES[0];
    const lat = _isValidLat(location[0]) ? location[0] : false;
    const lon = _isValidLon(location[1]) ? location[1] : false;
    if (lat && lon) {
      avlData.lat = lat;
      console.log(`spoofed lat: ${lat}`);
      avlData.lon = lon;
      console.log(`spoofed lon: ${lon}`);
    } else {
      console.log('unable to spoof: invalid latitude or longitude', location);
      console.log('---');
      return;
    }
    if (window.LOCATIONS_UPDATES.length > 1) {
      window.LOCATIONS_UPDATES = _.drop(window.LOCATIONS_UPDATES);
    }
    console.log(
      `${window.LOCATIONS_UPDATES.length} LOCATIONS_UPDATES remaining`,
    );

    console.log('---');
  }
  // </testing>

  const speed = getters.gpsSpeed;
  if (_.isFinite(speed)) {
    avlData.s = speed;
  }

  const MINIMUM_SPEED_TO_INCLUDE_HEADING = 0.6; // meters per second
  const hasSufficientSpeedToSendHeading =
    _.isFinite(speed) && speed >= MINIMUM_SPEED_TO_INCLUDE_HEADING;
  const heading = getters.gpsHeading;
  if (_.isFinite(heading) && hasSufficientSpeedToSendHeading) {
    avlData.h = heading;
  }

  // We frequently call this function without a position.
  if (!_isValidAvlPushData(avlData)) {
    if (getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)) {
      Segment.track('location-update-failed', {
        ...additionalTrackingInfo,
        ...avlData,
        errorMessage: 'Invalid AVL data',
      });
    }
    return false;
  }

  const fetchConfig = {
    method: 'POST',
    url: `/sendAvlData/${agencyKey}`,
    data: { avl: [avlData] },
  };
  let response = undefined;
  try {
    response = await fetch(fetchConfig);
    if (getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)) {
      Segment.track('sent-location-update', additionalTrackingInfo);
    }
  } catch (error) {
    reportError(fetchConfig, error);
    if (getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)) {
      Segment.track('location-update-failed', {
        ...additionalTrackingInfo,
        errorMessage: error.message,
      });
    }
  }

  const requestSuccess = _.get(response, ['data', 'success'], false);
  return requestSuccess;
};

export const logoutVehicle = async (
  { dispatch, commit, getters },
  { vc, reason, shouldClearPermanentlyAssignedVehicle = false },
) => {
  try {
    if (await vc.$confirm('Are you sure you want to log out?')) {
      const vehicleId = getters.vehicleId;
      if (vehicleId) {
        dispatch('sendClearVehicleAssignment', vehicleId);
      }
      // TODO(haysmike) Pull confirmation out and refactor so this can be handled separately
      if (shouldClearPermanentlyAssignedVehicle) {
        commit('permanentlyAssignedVehicleId', '');
      }
      if (getters.userHasFeatureAccess(OPERATOR_DISPATCH_MESSAGING)) {
        dispatch('messages/stop');
      }
      _resetCoreState(vc);
      vc.$router.push({
        name: 'operator-login',
        params: {
          agencyKey: getters.agencyKeyForRequest,
        },
      });
      const additionalTrackingInfo = {
        'reason': typeof reason !== 'string' ? 'Not specified' : reason,
        'user-initiated': true,
      };
      if (vehicleId) {
        additionalTrackingInfo.vehicle = vehicleId;
      }
      Segment.track('logout-vehicle', additionalTrackingInfo);
      Segment.identifyUser();
      return true;
    }
  } catch (error) {
    // `vue-simple-alert` rejects without an error :(
    if (error != null) {
      ErrorReporter.capture({
        level: 'error',
        messageOrException: 'Failed to log out operator',
        extraContext: { error },
      });
    }
  }
  return false;
};

export const logoutVehicleNoWarning = async (
  { commit, dispatch, getters },
  { vc, reason },
) => {
  const vehicleId = getters.vehicleId;
  _resetCoreState(vc);
  vc.$router.push({
    name: 'operator-login',
    params: {
      agencyKey: getters.agencyKeyForRequest,
    },
  });
  const additionalTrackingInfo = {
    'reason': typeof reason !== 'string' ? 'Not specified' : reason,
    'user-initiated': false,
  };
  if (vehicleId) {
    additionalTrackingInfo.vehicle = vehicleId;
  }
  if (getters.userHasFeatureAccess(OPERATOR_DISPATCH_MESSAGING)) {
    dispatch('messages/stop');
  }
  Segment.track('logout-vehicle', additionalTrackingInfo);
  Segment.identifyUser();
};

export const logoutAgency = async (
  { dispatch, commit, getters },
  { vc, reason },
) => {
  const logoutReason = typeof reason !== 'string' ? 'Not specified' : reason;
  try {
    if (
      await vc.$confirm(
        'Changing your agency will log you out. Are you sure you want to continue?',
      )
    ) {
      const agencyKey = getters.agencyKeyForRequest;
      const vehicleId = getters.currentVehicleId;
      if (vehicleId) {
        dispatch('sendClearVehicleAssignment', vehicleId);
        // Note _resetCoreState will effectively logout a vehicle
        Segment.track('logout-vehicle', {
          'reason': logoutReason,
          'vehicle': vehicleId,
          'user-initiated': true,
        });
      }
      if (getters.userHasFeatureAccess(OPERATOR_DISPATCH_MESSAGING)) {
        dispatch('messages/stop');
      }
      _resetCoreState(vc);
      commit('currentAgencyKey', '');
      vc.$router.push({ name: 'agency-selection' });
      Segment.track('logout-agency', {
        'reason': logoutReason,
        'agency': agencyKey,
        'user-initiated': true,
      });
      Segment.identifyUser();
    }
  } catch (error) {
    // `vue-simple-alert` rejects without an error :(
    if (error != null) {
      ErrorReporter.capture({
        level: 'error',
        messageOrException: 'Failed to log out agency',
        extraContext: { error },
      });
    }
  }
};

export const logoutAgencyNoWarning = async (
  { commit, dispatch, getters },
  { vc, reason },
) => {
  const agencyKey = getters.agencyKeyForRequest;
  _resetCoreState(vc);
  vc.$store.commit('currentAgencyKey', '');
  vc.$router.push({ name: 'agency-selection' });
  Segment.track('logout-agency', {
    'reason': typeof reason !== 'string' ? 'Not specified' : reason,
    'agency': agencyKey,
    // The _only_ place this action can currently be called from from a settings tap
    // on "Agency" within settings. Whether or not there is  warning depends on whether
    // a vehicle is assigned or not, but it is always user-initiated.
    'user-initiated': true,
  });
  Segment.identifyUser();
};

export const checkPwaGeolocationEnabledState = async (
  { commit, getters },
  { vc },
) => {
  if (getters.isNativeApp) {
    return 'native';
  }
  if (!_isGeolocationSupported()) {
    commit('geolocationEnabledState', 'unsupported');
    return 'unsupported';
  }
  if (!_isPermissionsCheckSupported()) {
    commit('geolocationEnabledState', 'attempting');
    return 'attempting';
  }
  try {
    const status = await navigator.permissions.query({ name: 'geolocation' });
    const enabledState = _.get(status, 'state', 'unknown');
    const previousEnabledState = getters.geolocationEnabledState;
    // As of May 2021, Firefox will always return "prompt" even when actually "denied"
    // or "granted". The first response from the watch call, however, does indicate a
    // denied status, so if we have a "denied" status from that and we are in Firefox,
    // keep the denied status.
    if (
      _isFirefox() &&
      enabledState === 'prompt' &&
      previousEnabledState === 'denied'
    ) {
      vc.$store.commit('geolocationEnabledState', 'denied');
      return 'denied';
    }
    vc.$store.commit('geolocationEnabledState', enabledState);
    return enabledState;
  } catch (error) {
    ErrorReporter.captureException(error);
    const errorState = `error: ${error.message}`;
    vc.$store.commit('geolocationEnabledState', errorState);
    return errorState;
  }
};

export const showPermissionDeniedInstructions = ({ getters }) => {
  if (getters.isNativeApp) {
    // Leave copy in case Product wants to re-enable
    // Currently dialog now called from the native layer
    // window.alert(
    //     'To enable GPS updates, Swiftly Onboard needs your permission to use this device’s location. To continue:\n\n' +
    //     '1. Go to tablet Settings.\n' +
    //     '2. Tap on Apps.\n' +
    //     '3. Look for Onboard App and tap on it.\n' +
    //     '4. Tap “Permissions”.\n' +
    //     '5. Enable location.\n' +
    //     '6. Return to the Swiftly Onboard app.'
    // )
  } else {
    window.alert(
      'To turn training mode off, Swiftly Onboard needs your permission to use this device’s location. To continue:\n\n' +
        '1. Open the Chrome app.\n' +
        '2. Visit https://onboard.goswift.ly.\n' +
        '3. Tap the padlock icon in the address bar.\n' +
        '4. Tap “Site Settings”.\n' +
        '5. Tap “Location Access”.\n' +
        '6. Tap “Allow”\n' +
        '7. Return to the Swiftly Onboard app.\n' +
        '8. Switch “Training Mode” off',
    );
  }
};

export const deregisterDevice = async ({ commit, getters }, vc) => {
  _resetCoreState(vc);

  // Clear items stored in local storage
  commit('registrationStatus', 'unregistered');
  commit('agencyKeys', []);
  commit('currentAgencyKey', '');
  commit('agencyConfigs', []);
  commit('developerMode', false);
  commit('headwayMode', false);
  commit('sendGeolocationUpdates', false);
  commit('email', null);

  if (vc.$route.name !== 'agency-selection') {
    vc.$router.push({ name: 'agency-selection' });
  }

  Segment.track('device-deregistration-complete', { uuid: getters.uuid });
  Segment.identifyUser();
};

export const fetchManeuvers = async ({ getters, state }, opts) => {
  const {
    currentAgencyConfig: { privateRoadsDatasetId },
    agencyKeyForRequest: agencyKey,
    currentStopPathIndex: nextStopPathIndex,
    currentTripId: tripId,
    userHasFeatureAccess,
    vehicleIsOnDetour,
    currentPosition,
  } = getters;
  if (!userHasFeatureAccess(ONBOARD_APP_TURN_BY_TURN)) {
    return;
  }
  if (tripId == null) {
    return;
  }

  const params = {
    agencyKey,
    tripId,
    ignore: 'restrictions',
    isOnDetour: vehicleIsOnDetour.toString(),
    locationAccuracy: currentPosition.coords.accuracy.toString(),
    ...opts,
  };
  if (nextStopPathIndex != null) {
    params.nextStopPathIndex = nextStopPathIndex.toString();
  }
  if (!state.useStadiaNavigation && privateRoadsDatasetId != null) {
    params.privateRoadsDatasetId = privateRoadsDatasetId;
  }
  const fetchConfig = {
    url: state.useStadiaNavigation
      ? '/getStadiaManeuvers'
      : '/getMapboxManeuvers',
    params,
  };
  try {
    const { data } = await fetch(fetchConfig);
    // This is triggered by the native layer, so if we're here the
    // `startNavigation()` function definitely exists.
    await window.NativeAPI.startNavigation(data);
  } catch (error) {
    reportError(fetchConfig, error);
  }
};

// For backwards compatibility
export const startNavigation = fetchManeuvers;

export const sendTrackingEvent = (
  {
    getters: {
      currentAgencyKey,
      currentOperator,
      currentVehicleId,
      currentRouteKey,
      currentBlockId,
      currentTripId,
      currentStopId,
      currentTripStopPathsByStopId,
    },
  },
  { eventName, ...additionalTrackingInfo },
) =>
  Segment.track(eventName, {
    agency: currentAgencyKey,
    operator: currentOperator?.key,
    vehicle: currentVehicleId,
    route: currentRouteKey,
    block: currentBlockId,
    tripId: currentTripId,
    nextStopId: currentStopId,
    gtfsStopSequence: currentTripStopPathsByStopId[currentStopId]?.gtfsStopSeq,
    ...additionalTrackingInfo,
  });

export const ensureEmail = async ({ commit, getters: { email, uuid } }) => {
  if (email == null && uuid !== 'swiftly-development-uuid') {
    const fetchConfig = { url: '/tablet' };
    try {
      const { data } = await fetch(fetchConfig);
      commit('email', data.registeredBy);
    } catch (error) {
      reportError(fetchConfig, error);
    }
  }
};

export const updateDistancesFromRouteLines = ({ commit }, opts) => {
  commit('distancesFromRouteLines', opts);
};
